import React, { useState } from 'react'
import SunCalc from 'suncalc2'
import Astronomy from 'astronomy-engine'
import { useTranslation } from 'react-i18next'
import Icon from './icons'
import Link from './link'
import getLocation from './get-location'
import getISSData from './get-iss-data'

const InfoLinks = ({ post }) => {
  const { t, i18n } = useTranslation()
  const location = getLocation(i18n.language)

  return [
    <MoonInfo key='moon' location={location} t={(key) => t(`objects.moon.${key}`)} />,
    <ISSInfo key='iss' location={location} t={(key) => t(`objects.iss.${key}`)} />,
    <PlanetsInfo key='planets' location={location} t={(key) => t(`objects.planets.${key}`)} />,
    <SunInfo key='sun' location={location} t={(key) => t(`objects.sun.${key}`)} />
  ]
}

const MoonInfo = (props) => {
  const { t } = props
  const { latitude, longitude } = props.location
  const now = new Date()
  const illumination = SunCalc.getMoonIllumination(now)
  const times = SunCalc.getMoonTimes(now, latitude, longitude, true)
  const moonAge = Math.round(10 * 29.53 * illumination.phase) / 10
  const image = Math.round((illumination.phase * 8)) % 8
  return <article className='card '>
    <Link to='/moon/' >
      <img src={`/assets/moon/${image}.jpg`} alt={t('img-alt')} />
    </Link>
    <header>
      <h2 className='post-title'>
        <Link to='/moon/' className='post-link'>
          <Icon type='Moon' />{ t('Moon') }
        </Link>
      </h2>
      <div className='post-meta'>{now.toLocaleDateString()}</div>
      {times.alwaysDown && <p>{t('not-visible')}</p>}
      {times.alwaysUp && <p>{t('always-visible')}</p>}
      {times.rise && <><Icon type='Rise' /> {t('rise')}: {times.rise.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}<br /></>}
      {times.set && <><Icon type='Set' /> {t('set')}: {times.set.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}<br /></>}
      <div>
        <Icon type='Lamp' />&nbsp;{t('illuminated')}: {Math.round(100 * illumination.fraction)} %<br />
        <Icon type='Age' />&nbsp;{t('age')}: {moonAge} {t('days')}
      </div>
    </header>
  </article>
}

const ISSInfo = (props) => {
  const { t } = props

  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const now = new Date()

  getISSData(props.location)
    .then(newData => {
      if (JSON.stringify(data) !== JSON.stringify(newData)) {
        newData && setData(newData)
        error && setError(false)
      }
    })
    .catch(() => !error && setError(true))

  return <article className='card '>
    <Link to='/iss/'>
      <img src={`/assets/iss.jpg`} alt={t('img-alt')} />
    </Link>
    <header>
      <h2 className='post-title'>
        <Link to='/iss/' className='post-link'>
          <Icon type='Satellite' />{`${t('ISS')} ${t('flyovers')}`}
        </Link>
      </h2>
      <div className='post-meta'>{now.toLocaleDateString()}</div>
      {error && <p>{t('error')}</p> }
      {!error && !data.length && <p>{t('calculating')}</p> }
      {!error && data.length ? data.slice(0, 4).map((info, i) => (
        <div key={i}>
          <Icon type='Clock' />&nbsp;
          {(new Date(info.risetime * 1000)).toLocaleDateString(undefined, { day: 'numeric', month: 'short' })}
          &nbsp;
          {(new Date(info.risetime * 1000)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </div>
      )) : <><br /></>
      }
    </header>
  </article>
}

const PlanetsInfo = (props) => {
  const { t } = props
  const { latitude, longitude, altitude } = props.location
  const now = new Date()
  const earth = Astronomy.MakeObserver(latitude, longitude, altitude)
  const venusRise = (new Date((Astronomy.SearchRiseSet('Venus', earth, 1, now, 1))))
  const venusSet = (new Date((Astronomy.SearchRiseSet('Venus', earth, -1, now, 1))))
  const marsRise = (new Date((Astronomy.SearchRiseSet('Mars', earth, 1, now, 1))))
  const marsSet = (new Date((Astronomy.SearchRiseSet('Mars', earth, -1, now, 1))))
  const jupiterRise = (new Date((Astronomy.SearchRiseSet('Jupiter', earth, 1, now, 1))))
  const jupiterSet = (new Date((Astronomy.SearchRiseSet('Jupiter', earth, -1, now, 1))))
  const saturnRise = (new Date((Astronomy.SearchRiseSet('Saturn', earth, 1, now, 1))))
  const saturnSet = (new Date((Astronomy.SearchRiseSet('Saturn', earth, -1, now, 1))))

  const localeSetting = { hour: '2-digit', minute: '2-digit' }
  return <article className='card '>
    <Link to='/planets/'>
      <img src={`/assets/planets/thumbnail.jpg`} alt={t('planet-space')} />
    </Link>
    <header>
      <h2 className='post-title'>
        <Link to='/planets/' className='post-link'>
          <Icon type='Planet' />{t('planets')}
        </Link>
      </h2>
      <div className='post-meta'>{now.toLocaleDateString()}</div>
      <Icon type='Venus' /> {t('Venus')}: {
        (venusRise > venusSet)
          ? <><Icon type='Eye' /> <Icon type='Set' /> {venusSet.toLocaleString([], localeSetting)}</>
          : <> <Icon type='Rise' /> {venusRise.toLocaleString([], localeSetting)}</>
      }<br />
      <Icon type='Mars' /> {t('Mars')}: {
        (marsRise > marsSet)
          ? <><Icon type='Eye' /> <Icon type='Set' /> {marsSet.toLocaleString([], localeSetting)}</>
          : <> <Icon type='Rise' /> {marsRise.toLocaleString([], localeSetting)}</>
      }<br />
      <Icon type='Jupiter' /> {t('Jupiter')}: {
        (jupiterRise > jupiterSet)
          ? <><Icon type='Eye' /> <Icon type='Set' /> {jupiterSet.toLocaleString([], localeSetting)}</>
          : <> <Icon type='Rise' /> {jupiterRise.toLocaleString([], localeSetting)}</>
      }<br />
      <Icon type='Saturn' /> {t('Saturn')}: {
        (saturnRise > saturnSet)
          ? <><Icon type='Eye' /> <Icon type='Set' /> {saturnSet.toLocaleString([], localeSetting)}</>
          : <> <Icon type='Rise' /> {saturnRise.toLocaleString([], localeSetting)}</>
      }
    </header>
  </article>
}

const SunInfo = (props) => {
  const { t } = props
  const { latitude, longitude } = props.location
  const now = new Date()
  const localeSetting = { hour: '2-digit', minute: '2-digit' }
  const times = SunCalc.getTimes(now, latitude, longitude)
  return <article className='card '>
    <Link to='/sun/'>
      <img src={`/assets/sun/title.jpg`} alt={t('alt-sunset')} />
    </Link>
    <header>
      <h2 className='post-title'>
        <Link to='/sun/' className='post-link'>
          <Icon type='Sun' />{t('Sun')}
        </Link>
      </h2>
      <div className='post-meta'>{now.toLocaleDateString()}</div>
      {times.nightEnd && <><Icon type='NightBridge' /> {t('nightEnd')}: {times.nightEnd.toLocaleTimeString([], localeSetting)}<br /></>}
      {times.sunrise && <><Icon type='SunRise' /> {t('sunrise')}: {times.sunrise.toLocaleTimeString([], localeSetting)}<br /></>}
      {times.sunset && <><Icon type='SunSet' /> {t('sunset')}: {times.sunset.toLocaleTimeString([], localeSetting)}<br /></>}
      {times.night && <><Icon type='NightStars' /> {t('night')}: {times.night.toLocaleTimeString([], localeSetting)}<br /></>}
    </header>
  </article>
}

export default InfoLinks
