import React from 'react'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()
  return (
    <div className='hero-header'>
      <div className='headline'>{t('home.title')}</div>
      <div
        className='primary-content'
        dangerouslySetInnerHTML={{ __html: t('home.description') }}
      />
      {/* <Link to='/contact' className='button -primary'>Get in touch &rarr;</Link> */}
    </div>
  )
}
