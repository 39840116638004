import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

const SEO = (props) => {
  const { t } = useTranslation()
  const { title, description, meta = [], pageContext } = props

  const site = require('../../site-meta-data.json')
  const { lang, originalPath } = pageContext

  const metaDescription = description || t('site.description')
  const host = site.siteUrl
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      titleTemplate={`%s | ${t('site.title')}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:locale`,
          content: lang
        }
      ].concat(meta)}
      link={[
        {
          rel: 'canonical',
          href: `${host}/${lang}${originalPath}`
        },
        ...site.supportedLanguages.map(supportedLang => ({
          rel: 'alternate',
          hrefLang: supportedLang,
          href: `${host}/${supportedLang}${originalPath}`
        }))
      ]}
    >
      <title>{title}</title>
    </Helmet>
  )
}

export default SEO

// {
//   rel: 'alternate',
//   hrefLang: 'x-default',
//   href: `${host}${originalPath}`
// },
