import React from 'react'
import { graphql } from 'gatsby'
import PostLink from '../components/post-link'
import HeroHeader from '../components/heroHeader'
import InfoLinks from '../components/info-links'
import SEO from '../components/seo'
import { useTranslation } from 'react-i18next'

const IndexPage = (props) => {
  const {
    pageContext,
    data: {
      allMdx: { edges }
    }
  } = props
  const { t, i18n } = useTranslation()
  const tt = (key) => t(`home.${key}`)
  const Posts = edges
    .filter(edge => edge.node.frontmatter.includeInHome)
    .filter(edge => edge.node.frontmatter.lang === i18n.language)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return <>
    <SEO
      title={tt('seoTitle')}
      pageContext={pageContext} />
    <HeroHeader />
    <div className='four-grids'>
      {<InfoLinks />}
    </div>
    <br />
    <div className='grids'>
      {Posts}
    </div>
  </>
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(sort: { order: ASC, fields: [frontmatter___priority] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            lang
            includeInHome
            path
            title
            thumbnail
            hideImageInPage
            priority
            description
            titleIcon
          }
        }
      }
    }
  }
`
