import React from 'react'
import { Link } from 'gatsby'
import Icon from './icons'

const PostLink = ({ post }) => (
  <article className='card '>
    <Link to={post.frontmatter.path}>
      {!!post.frontmatter.thumbnail && (
        <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title + '- Featured Shot'} />
      )}
    </Link>
    <header>
      <h2 className='post-title'>
        <Link to={post.frontmatter.path} className='post-link'>
          {post.frontmatter.titleIcon && <Icon type={post.frontmatter.titleIcon} />}{post.frontmatter.title}
        </Link>
      </h2>
      <div className='post-meta'>{post.frontmatter.date}</div>
      {post.frontmatter.description}
    </header>
  </article>
)
export default PostLink
